<template>
    <div>
        <span
            v-for="(channel, i) in accountChannels"
            :key="i">
            <icon
                class="mr-2"
                :name="channel"
                color="#B8C2C7"
                size="20" />
        </span>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { PLATFORM_MAPPING } from '@/helpers/globals';

export default {
    components: {
        Icon
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        accountChannels() {
            const accountChannels = new Set();
            this.item.products.data.forEach(product => {
                const platformKey = PLATFORM_MAPPING[product.platform_id];
                if (platformKey) {
                    accountChannels.add(platformKey);
                }
            });
            return Array.from(accountChannels);
        }
    }
};
</script>
